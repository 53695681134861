const BLOCK = 1;
function isWorkDate(targetDate) {
  return (targetDate.getDay() !== 0 && targetDate.getDay() !== 6);
}
export const calculateMonthlyEarnings = (currentDate, startWorkingTime, endWorkingTime, monthlySalary) => {
  const startWorkingHour = new Date(startWorkingTime).getHours();
  const endWorkingHour = new Date(endWorkingTime).getHours();
  // 1日の稼働時間
  const blockInDay = ((endWorkingTime.getHours() - startWorkingTime.getHours()) * 60 * 60) / BLOCK;
  // 前日までの稼働時間
  let monthlyWorkBlock = 0;
  let workBlock = 0;
  let tmpDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  for (let i = 1; i <= 31; i++) {
    tmpDate.setDate(i);
    if (tmpDate.getMonth() !== currentDate.getMonth()) {
      break;
    }
    if (!isWorkDate(tmpDate)) {
      continue;
    }
    monthlyWorkBlock += blockInDay;
    if (i < currentDate.getDate()) {
      workBlock += blockInDay;
    } else if (i === currentDate.getDate()) {
      // 時
      if (currentDate.getHours() >= endWorkingHour) {
        workBlock += blockInDay;
      } else if (currentDate.getHours() >= startWorkingHour) {
        const todayStartTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), startWorkingHour, 0, 0);
        const diff = currentDate.getTime() - todayStartTime.getTime();
        workBlock += (diff / 1000) / BLOCK;
      }
    }
  }
  const blockSalary = monthlySalary / monthlyWorkBlock;
  const currentSalary = Math.floor(monthlySalary * (workBlock / monthlyWorkBlock));
  // console.log(`currentSalary=${currentSalary}, 現在Block= ${workBlock} 、1Block=${blockSalary}, 今月Block=${monthlyWorkBlock}`);
  return {
    'currentSalary': currentSalary
    , 'currentBlock': workBlock
    , 'blockSalary': blockSalary
    , 'monthlyWorkBlock': monthlyWorkBlock
  };
}
