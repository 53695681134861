import './App.css';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { calculateMonthlyEarnings } from './Calc';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme();

function workTimeText(value) {
  return `${value}時`;
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://line.toroneko.com/">
        toroneko.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function toDispSalary(value) {
  return value.toLocaleString() + '円';
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function App() {
  const [currentSalary, setCurrentSalary] = React.useState(0);
  const [blockSalary, setBlockSalary] = React.useState(0);
  const [monthlyWorkBlock, setMonthlyWorkBlock] = React.useState(0);
  const [workTime, setWorkTime] = React.useState([9, 18]);
  const [salary, setSalary] = React.useState(360000);
  const [progress, setProgress] = React.useState(0);
  const [curDate, setCurDate] = React.useState(new Date());

  const handleWorkTimeChange = (event, newValue) => {
    setWorkTime(newValue);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
    /**
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       setSalary(e.target.value);
    }
     */
    // const salary = event.target.value.replace(/[^0-9]/g, '');
  };

  React.useEffect(() => {
    const reloadSalary = function() {
      const start = workTime[0];
      const end = workTime[1];
      const monthlySalary = salary;
      const startWorkingTime = new Date(2023, 1, 1, start, 0, 0);
      const endWorkingTime = new Date(2023, 1, 1, end, 0, 0);

      const currentMonthlyEarnings = calculateMonthlyEarnings(new Date(), startWorkingTime, endWorkingTime, monthlySalary);
      setCurrentSalary(toDispSalary(currentMonthlyEarnings.currentSalary));
      const progress = Math.floor((currentMonthlyEarnings.currentBlock / currentMonthlyEarnings.monthlyWorkBlock) * 100);
      setProgress(progress);
      setCurDate(new Date());
      setBlockSalary(currentMonthlyEarnings.blockSalary);
      setMonthlyWorkBlock(currentMonthlyEarnings.monthlyWorkBlock);
    }
    reloadSalary();
    const pid = setInterval(reloadSalary, 250);
    // console.log('set Interval:' + pid);
    return () => {
      // console.log('clear Interval:' + pid);
      clearInterval(pid);
    };
  }, [workTime, salary]);

  return (
    <div className="App">
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            収入メーター
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Grid item xs={12} md={4} lg={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              稼いだ金額
            </Typography>
            <Typography component="p" variant="h4">
              {currentSalary}<CircularProgressWithLabel value={progress} />
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              on {curDate.getFullYear()}-{('00' + (curDate.getMonth() + 1)).slice(-2)}-{('00' + (curDate.getDate())).slice(-2)} {curDate.getHours()}:{('00' + curDate.getMinutes()).slice(-2)}:{('00' + curDate.getSeconds()).slice(-2)}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              {(Math.floor(blockSalary * 100) / 100).toLocaleString()}円/秒<br/>
              {Math.floor(blockSalary * 60 * 60).toLocaleString()}円/時<br/>
              今月の稼働時間：{(monthlyWorkBlock / 60 / 60).toLocaleString()} 時間
            </Typography>
          </Paper>
        </Grid>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
          </Typography>
          <React.Fragment>
            {/*
            <Typography variant="h6" gutterBottom>
              Shipping address
            </Typography>
            */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="salary"
                  name="salary"
                  label="月収"
                  fullWidth
                  autoComplete="月収"
                  variant="standard"
                  value={salary}
                  onChange={handleSalaryChange}
                />
              </Grid>
              <Grid item xs={12}>
                稼働時間：{workTime[0]}時-{workTime[1]}時
                <Slider
                  getAriaLabel={() => 'Work time range'}
                  min={0}
                  step={1}
                  max={23}
                  value={workTime}
                  onChange={handleWorkTimeChange}
                  valueLabelDisplay="auto"
                  // getAriaValueText={workTimeText}
                  valueLabelFormat={workTimeText}
                />
              </Grid>
              {/*
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">稼働曜日</FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="mon"
                      control={<Checkbox />}
                      label="Mon"
                      labelPlacement="bottom"
                      defaultChecked
                    />
                    <FormControlLabel
                      value="tue"
                      control={<Checkbox />}
                      label="Tue"
                      labelPlacement="bottom"
                      defaultChecked
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
                  label="Use this address for payment details"
                />
              </Grid>
                */}
            </Grid>
            {/*
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
              >
                Next
              </Button>
            </Box>
            */}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
    </div>
  );
}

export default App;
